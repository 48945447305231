/* ============= Font Text ============== */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wdth,wght@12..96,75..100,200..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* ============= Css Table ================= */
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

/* ============== Reset CSS =========== */

* {
  margin: 0;
  padding: 0;
  text-shadow: none !important;
}

body,
html {
  position: relative;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-margin: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: #a3a9af;
}

div#root {
  overflow-y: hidden;
  height: 100vh;
}

/*/* ===================================== Css input cus */
.styled-placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #9b9b9b;
}

.label-input {
  color: #9b9b9b;
}

input:hover {
  border-color: #000000;
}

.label-input {
  position: absolute;
  left: 16px;
  top: 20px;
  transition: all 0.2s ease-in-out;
  z-index: 1;
  letter-spacing: 0.5px;
  width: 80%;
  pointer-events: none;
}

.focus-label {
  top: 6px;
  left: 16px;
  font-size: 10px;
  transition: 0.3s;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
input:-moz-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

div#root.dark input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px rgb(65, 65, 65) inset !important;
  box-shadow: 0 0 0px 1000px rgb(65, 65, 65) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For Firefox */
div#root.dark input:-moz-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px rgb(65, 65, 65) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* ===================================== */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  display: none !important;
}

/* End Css input cus */

div#root.light {
  background-color: #a3a9af;
}

div#root.dark {
  background-color: #151515;
}

div#root.dark .ps-menu-button svg,
div#root.dark .ps-menu-button path {
  fill: #ffff00;
  stroke: #ffff00;
}

div#root.dark .ps-menu-icon svg,
div#root.dark .ps-menu-button path {
  stroke: #ffff00;
  fill: #ffff00;
}

div#root.light .ag-theme-quartz {
  --ag-background-color: #ffffff;
  --ag-font-size: 13px;
  --ag-font-family: Poppins;
  --ag-foreground-color: #1c1c1c;
}

div#root.dark .ag-theme-quartz {
  --ag-background-color: #252525;
  --ag-font-size: 13px;
  --ag-font-family: Poppins;
  --ag-foreground-color: #ffffff;
}

aside {
  padding: 0 !important;
  border: none !important;
}

@media (max-width: 900px) {
  .box-layout {
    padding: 0px !important;
  }
}

/* ============== End Reset CSS =========== */

/* ========= SCROLLBAR CUSTOM ========== */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

scrollbar {
  width: 10px;
}

scrollbar-track {
  background: #f1f1f1;
}

scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* ========= SCROLLBAR CUSTOM ========== */

/* ========= Overight Css MUI ========= */

.css-wdnv8a-MuiGrid-root > .MuiGrid-item {
  padding: 0 !important;
}

/* =========== End ==========*/

/* =========== Custom UI Table =========== */
.ag-header-cell-resize {
  display: none;
}

.ag-root-wrapper.ag-layout-normal {
  border: none;
}
